import React, { useState } from 'react';
import { BetSlipItem } from '../../types/Types';

interface BetInputProps {
  marketItem: BetSlipItem;
  onBetChange: (value: number, marketItem: BetSlipItem) => void;
}

const BetInputComponent: React.FC<BetInputProps> = ({ marketItem, onBetChange }) => {
  const [betStake, setStake] = useState<number | string>('');
  const [clickedButton, setClickedButton] = useState<number | null>(null);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const numericValue = /^\d+(\.\d+)?$/.test(value) ? Number(value) : 0; // allows only numbers > 0 to be inserted , 0 displayed by default 
    setStake(numericValue);
    onBetChange(numericValue as number, marketItem);
  };

  const handleButtonClick = (amount: number) => {
    const newStake = betStake !== '' ? (betStake as number) + amount : amount;
    if(newStake>=0){
    setStake(newStake);
    }
    onBetChange(newStake, marketItem);
    setClickedButton(amount);
    setTimeout(() => setClickedButton(null), 100); // Reset after 100ms
  };

  const amounts = [5, 10, 50, 100];

  return (
    <div>
      <label className="md:text-sm text-xs font-medium text-white">Stake</label>

      <div className="relative">
        <div className="w-full border border-black rounded-lg flex items-center overflow-hidden">
          <div className="lg:text-lg absolute left-2 top-2 text-gray-700">
            R
          </div>

          <input
            type="text"
            id={`betAmount_${marketItem.marketNumber}`}
            value={betStake}
            onChange={handleInputChange}
            placeholder="Stake"
            className="w-full p-2 pl-8 lg:pl-8 text-primary"
          />

          <div className="flex flex-col absolute top-1 right-1">
            <button
              className={`text-xs px-2 text-primary ${
                clickedButton === 1 && "bg-third"
              }`}
              onClick={() => handleButtonClick(1)}
            >
              ▲
            </button>

            <button
              className={`text-xs px-2 text-primary ${
                clickedButton === -1 && "bg-third"
              } ${betStake === "" || Number(betStake) <= 1 ? "hidden" : ""}`}
              onClick={() => handleButtonClick(-1)}
              disabled={betStake === ""}
            >
              ▼
            </button>
          </div>
        </div>
      </div>

      <div className="flex grid grid-cols-4 divide-x pt-3 lg:pl-2 pl-6">
        {amounts.map((amount, index) => (
          <button
            key={index}
            className={`w-14 lg:h-10 h-8 rounded-lg font-bold bg-third shadow-xl ${
              clickedButton === amount && "bg-secondary text-white"
            }`}
            onClick={() => handleButtonClick(amount)}
          >
            +{amount}
          </button>
        ))}
      </div>
    </div>
  );
};

export default BetInputComponent;
