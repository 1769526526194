import React, { useContext, useEffect, useState } from "react";
import { Market, HorseHeaders, LegItem} from "../../types/Types";
import { AppContext } from "../../services/ContextProvider";
import {fetchMarket, fetchSilk } from "../../services/PunterService";
import arrowDownList from "../../Icons/arrowDownList.svg";
import { generateFixedBettingGrid } from "../../services/FixedBetService";
import { generateFixedHeaderGrid } from "../../services/FixedBetServiceHeader";
import SharedMarketListComponent from "./SharedMarketListComponent";

const FixedBetViewerComponent = () => {
  const { appStateStore, setAppStateStore } = useContext(AppContext);
  const [marketList, setMarketList] = useState<Market[]>([]);
  const [headerList, setHeaderList] = useState<HorseHeaders[]>([]);

  if (appStateStore.currentMarketList && appStateStore.currentBetSlipItemList) {
    appStateStore.currentMarketList.forEach((item) => {
      appStateStore.currentBetSlipItemList?.forEach((bts) => {
        if (item.marketNumber === bts.marketNumber && item.eventId === bts.eventId) {
          item.columns?.forEach((col) => {
            if (
              bts.marketNumber === col.columnMarketNumber &&
              bts.marketColumnName === col.columnName &&
              col.columnBetTypeId === bts.betTypeId
            ) {
              col.isSelected = true;
            }
          });
        }
      });
    });
  }

  // Update currentHorseHeadersList in appStateStore when selectedEventBetTypeId or currentEventBetType.betTypeSpecificId changes
  useEffect(() => {

    const FixedHeadersData: HorseHeaders[] = [
      {
        headerColumns: []
      },
    ];


    var fixedHeaderItem = generateFixedHeaderGrid(
      FixedHeadersData,
      appStateStore.currentEventBetType?.betTypeId||1,
      appStateStore.currentEventBetType?.betTypeSpecificId
    );

    setHeaderList(fixedHeaderItem)
    

    const fetchData = async () => {
      const response = await fetchMarket(1, appStateStore.currentEvent?.eventId || 1, appStateStore.currentEventBetType?.betTypeId||1);
      const responseSilkData = await fetchSilk(1, appStateStore.currentEvent?.eventId || 1, appStateStore.currentEventBetType?.betTypeId||1);
      const responsePlace = await fetchMarket(1, appStateStore.currentEvent?.eventId || 1, 13);
      if (response) {
        setAppStateStore((prevState: any) => ({
          ...prevState,
          currentHorseHeadersList: fixedHeaderItem,
          currentHorseHeaders: { headerColumns: headerList },
          currentMarketList: generateFixedBettingGrid(response,responsePlace, 1, appStateStore.currentEventBetType?.betTypeSpecificId), 
          //currentSilk:response
          currentMarket: { silk: responseSilkData },
        }));
      }
      setMarketList(response);
    };

    const checkEvent = () => {
      const currentDate = new Date();
      
      if(appStateStore.currentEvent){
        const eventDate = new Date (appStateStore.currentEvent.eventDate)
      if (currentDate > eventDate || currentDate.getTime() === eventDate.getTime()) {
        setAppStateStore((prevState) => ({
          ...prevState,
          marketDisable: true,
        }));
      } else if (appStateStore.currentEvent?.eventStatusId !== 1) {
        setAppStateStore((prevState) => ({
          ...prevState,
          marketDisable: true,
        }));
      } else {
        setAppStateStore((prevState) => ({
          ...prevState,
          marketDisable: false,
        }));
      }
    };
  }

   
    checkEvent();
    fetchData();
    
  }, [appStateStore.currentEventBetType?.betTypeSpecificId, appStateStore.currentEvent?.eventId, appStateStore.currentBetSlipItemList?.length])


  const addMarketToBetSlip = async (
    market: Market,
    columnNumber: number,
    columnOdd:number,
    marketNumber: number,
    betTypeId: number,
    betTypeSpecificId:number,
    columnBetTypeId:number,
    columnName:string
  ) => {
    if (appStateStore.currentMarketList) {
      
      var newMarkets = [...appStateStore.currentMarketList];
      var legsToSend: LegItem[] = [];
      newMarkets.forEach((mk) => {
        mk.marketUniqueIdentifier = market.eventId + columnName + market.marketNumber
        if (mk.marketNumber === marketNumber) {

          mk.columns?.forEach((col) => {
            if (col.columnNumber === columnNumber) {
              col.isSelected = !col.isSelected;
              
            }
          });
        }

      }
      );

      newMarkets.forEach((mkt) => {
        mkt.columns?.forEach((cl) => {
          if (cl.isSelected && mkt.marketNumber === cl.columnMarketNumber) {
            // Create leg item
            const legToAdd: LegItem = {
              legNo: 1,
              betTypeId: columnBetTypeId?columnBetTypeId:1,
              eventId: mkt.eventId,
              marketNumber: mkt.marketNumber,
              betTypeSpecificId: betTypeSpecificId,
              specifiers: mkt.specifiers,
              dataProviderId: mkt.dataProviderId || 1,
              odd: isNaN(columnOdd)?0:columnOdd,
              stake: 0,
              payout: 0,
              sportId: mkt.sportId || 2,
              legSelection: []
            };

            // Check if legToAdd already exists in legsToSend
            const existingLeg = legsToSend.find(leg => (
              leg.eventId === legToAdd.eventId &&
              leg.betTypeId === legToAdd.betTypeId &&
              leg.betTypeSpecificId === legToAdd.betTypeSpecificId &&
              leg.dataProviderId === legToAdd.dataProviderId &&
              leg.stake === legToAdd.stake &&
              leg.payout === legToAdd.payout &&
              leg.sportId === legToAdd.sportId &&
              JSON.stringify(leg.legSelection) === JSON.stringify(legToAdd.legSelection)
            ));

            // If the legToAdd doesn't already exist, push it to legsToSend
            if (!existingLeg) {
              legsToSend.push(legToAdd);
            }
          }
        });

        setAppStateStore((prevState: any) => ({
          ...prevState,
          currentMarketList: newMarkets,
          currentBetSlipItem: {
            betTypeId: betTypeId,
            betTypeName:appStateStore.currentEventBetType?.description?appStateStore.currentEventBetType?.description:`Fixed ${columnName}`,
            betTypeSpecificId: betTypeSpecificId,
            categoryName: appStateStore.currentTournament?appStateStore.currentTournament?.categoryName|| appStateStore.currentCategory?.categoryName: '',
            tournamentName: prevState.currentTournament?.tournamentName,
            eventName: prevState.currentEvent?.eventName,
            eventId: prevState.currentEvent?.eventId,
            eventDate: prevState.currentEvent?.eventDate,
            marketNumber: market.marketNumber,
            marketName: market.marketName,
            Odd:columnOdd,
            stake: prevState.currentBetSlipItem?.stake,
            payout: prevState.currentBetSlipItem?.payout,
            dataProviderId: market.dataProviderId,
            marketColumnName:columnName,
            marketUniqueIdentifier:market.marketUniqueIdentifier,
            leg: legsToSend
          },
        }));
      })
    };
  }

  const getButtonWidth = (betTypeId: number, columnName: string) => {
    return betTypeId === 1 &&
      (columnName === '1st' || columnName === '2nd' || columnName === '3rd' ||
        columnName === '4th' || columnName === 'Win' || columnName === 'Place')
      ? 'lg:w-20 w-16' : 'lg:w-60 w-40';
  };

  return (
    <>
      <div className="font-inter bg-primary text-white">
        <div className="w-full lg:h-1/2  max-h-[80vh] lg:max-h-[82vh] pb-10 lg:pb-0 md:pb-5 sm:pb-2 overflow-y-auto">
          <table className=" text-center lg:p-10 p-2 w-full">
            <thead className="bg-third lg:p-5 px-1 text-xs lg:text-lg w-full">
              <tr>
                <th className="w-1/2 lg:w-auto">
                  <div className="lg:px-2 flex">
                    <img
                      src={arrowDownList}
                      className="ml-2"
                      alt="arrow down list"
                    />
                  </div>
                </th>

                {headerList &&
                  headerList.map(
                    (hd) =>
                      hd.headerColumns &&
                      hd.headerColumns.map((cl) => (
                        <th
                          key={cl.headerNumber}
                          className="text-[10px] lg:text-base lg:p-5"
                        >
                          {cl.headerName}
                        </th>
                      ))
                  )}
              </tr>
            </thead>

            <tbody className="border-b-5 ">
              {marketList &&
                marketList.map((mkt) => (
                  <tr
                    className="border-b-2"
                    key={`${mkt.marketNumber}-${mkt.eventId}`}
                  >
                    {/* Market List that allows openbets and fixedBets to share it */}
                    <SharedMarketListComponent currentMarket={mkt} />

                    {mkt.columns &&
                      mkt.columns.map((cl, index) => (
                        <td
                          className={`text-[11px] lg:text-base ${
                            mkt.silk?.length === 0 ? "py-1" : ""
                          }
                    ${
                      getButtonWidth(mkt.betTypeId, cl.columnName) ===
                      "lg:w-60 w-40"
                        ? ""
                        : ""
                    }`}
                          key={index}
                        >
                          {["Opening", "Price", "Previous"].includes(
                            cl.columnName
                          ) ? (
                            Number(cl.columnOdd).toFixed(2)
                          ) : (
                            <button
                              className={`lg:h-full lg:p-5 p-2 rounded-lg font-bold h-14
  ${getButtonWidth(mkt.betTypeId, cl.columnName)}
  ${cl.isSelected ? "bg-secondary text-black" : "bg-white text-black"} 
  ${
    appStateStore.marketDisable
      ? "opacity-50 cursor-not-allowed bg-secondary"
      : ""
  }`}
                              disabled={appStateStore.marketDisable}
                              onClick={() => {
                                addMarketToBetSlip(
                                  mkt,
                                  cl.columnNumber,
                                  Number(cl.columnOdd),
                                  mkt.marketNumber,
                                  Number(cl.columnBetTypeId),
                                  Number(cl.columnBetTypeSpecificId),
                                  Number(cl.columnBetTypeId),
                                  String(cl.columnName)
                                );
                              }}
                              key={mkt.marketNumber}
                            >
                              {typeof cl.columnOdd !== "string"
                                ? Number(cl.columnOdd).toFixed(2)
                                : cl.columnOdd}
                            </button>
                          )}
                        </td>
                      ))}
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default FixedBetViewerComponent;
