import React, { useState, useContext } from "react";
import { AppContext } from "../../../services/ContextProvider";
import { fetchEvent } from "../../../services/PunterService";
import { Event, Tournament } from "../../../types/Types";
import angleDown from '../../../Icons/angleDown.svg'

interface RaceProps {
  handleEventClick: (event: Event, tourn: Tournament) => void;
  onChange: React.Dispatch<React.SetStateAction<number | null>>;
}

const Race: React.FC<RaceProps> = ({ handleEventClick, onChange }) => {
  const { appStateStore, setAppStateStore } = useContext(AppContext);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState<string>(appStateStore.currentEvent?.eventName || 'Race 1');
  const [events, setEvents] = useState<Event[]>([]);

  const toggleDropdown = async () => {
    setIsOpen(!isOpen);

    if (!isOpen) {
        const response = await fetchEvent(
          1,
          appStateStore.currentTournament?.tournamentId || 0
        );

        if (response) {
          setEvents(response);
        }
    }
  };

  const handleOptionClick = (option: string) => {
    setSelectedOption(option);
    onChange(null);
    setIsOpen(false);
  };

  const handleEventsClick = async (event: Event) => {
    if (event) {
      const _event = await fetchEvent(1, appStateStore.currentEvent?.tournamentId||1);

      setAppStateStore((appStateStore) => ({
        ...appStateStore,
        currentEvent: event,
        currentEventList: _event,
      }));
    }
  };


  return (
    <div className="text-left">

      <div
        className="flex items-center justify-between lg:w-60 w-40 px-4 py-2 bg-primary border border-third 
                  rounded-lg cursor-pointer lg:text-base text-xs"
        onClick={toggleDropdown}>

        <span>
          {appStateStore.currentEvent?.eventName ? appStateStore.currentEvent?.eventName : selectedOption}
        </span>

        <img src={angleDown} className={`text-white w-3 h-3 ml-2 ${isOpen ? "transform rotate-180" : ""}`} alt="angle down" />
      </div>

      {isOpen && (
        <div className="fixed absolute z-10 -mt-2 lg:w-60 w-40 bg-third border border-black rounded-b-md 
                        shadow-lg lg:text-base text-xs">
          {events.length > 0 ? (
            events.map((evt) => (

              <div className={`px-4 py-2 cursor-pointer 
              ${appStateStore.currentEvent?.eventId === evt.eventId ? "bg-secondary font-bold text-black" : "hover:bg-secondary text-black"}`}
                key={evt.eventId}
                onClick={() => {
                  //handleEventClick(evt, new Tournament(){});
                  handleEventClick(evt, appStateStore.currentTournament as Tournament);
                  handleOptionClick(evt.eventName);
                  handleEventsClick(evt)}}>
                {evt.eventName}
              </div>

            ))
          ) : (
            <p className="text-center">No Events available</p>
          )}
        </div>
      )}
    </div>
  );
};

export default Race;
