import React, { useContext, useEffect } from "react";
import xmark from "../../Icons/xmark.svg"
import plug from "../../Icons/plug.svg"
import { AppContext } from "../../services/ContextProvider";

const TokenNotAvailableComponent = () => {
    const {setAppStateStore } = useContext(AppContext);

    const handleRemovePopUp = async () => {
        setAppStateStore((appStateStore) => ({
            ...appStateStore,
            isCurrentSessionTokenAvailable: false
        }));
    };
    return (
      <div className="w-full font-inter lg:text-base text-xs">
        <div className="w-ful l p-2 flex">
          <div className="fixed top-3 right-10 w-5 rounded-md p-1 pt-2 lg:text-right items-end bg-primary">
            <button onClick={handleRemovePopUp}>
              <img src={xmark} className="w-5 h-full text-white" alt="x" />
            </button>
          </div>
        </div>
        <div className="w-full text-center font-bold text-3xl">
          <p>Failed To Connect</p>
        </div>
        <div className="flex p-20 items-center justify-center w-full">
          <img
            src={plug}
            className="w-60 h-full text-white"
            alt="Can't Connect"
          />
        </div>
        <div className="flex justify-center items-center text-center w-full h-full">
          <p>Please contact support team</p>
        </div>
      </div>
    );
}

export default TokenNotAvailableComponent;
