import React, { useState, useEffect, useContext } from 'react';
import { fetchEventBetType } from '../../../services/PunterService';
import { EventBetType } from '../../../types/Types';
import angleDown from '../../../Icons/angleDown.svg'
import { AppContext } from '../../../services/ContextProvider';

interface EventBetTypeComponentProps {
  onSelect: (selectedBetType: number) => void;
}

const EventBetTypes: React.FC<EventBetTypeComponentProps> = ({ onSelect }) => {
  const { appStateStore, setAppStateStore } = useContext(AppContext);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState<number>(1);
  const [eventBetTypes, setEventBetTypes] = useState<EventBetType[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetchEventBetType(1, appStateStore.currentEvent?.eventId || 1);
      if (response) {
        setEventBetTypes(response.sort((a, b) => a.betTypeId - b.betTypeSpecificId));
        setSelectedOption(1);
      }
    };
    fetchData();
  }, [appStateStore.currentEvent]);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (betTypeId: number, betTypeSpecificId: number) => {
    setSelectedOption(betTypeId);
    setIsOpen(false);
    onSelect(betTypeId);
  };

  const handleEventBetTypeClick = async (eventBetType: EventBetType) => {
    if (eventBetType) {
      const _eventBetType = await fetchEventBetType(appStateStore.currentEvent?.dataProviderId||1, appStateStore.currentEvent?.eventId || 1);

      setAppStateStore((appStateStore) => ({
        ...appStateStore,
        currentEventBetType: eventBetType,
        currentEventBetTypeList: _eventBetType,
      }));
    }
  };

  return (
    <div className="text-left">
      <div
        className="flex items-center justify-between lg:w-60 w-40 px-4 py-2 bg-primary border border-third 
               rounded-lg cursor-pointer lg:text-base text-xs"
        onClick={toggleDropdown}
      >
        <span>
          {appStateStore.currentEventBetType
            ? appStateStore.currentEventBetType.description
            : eventBetTypes.find((bt) => bt.betTypeId === selectedOption)
                ?.description}
        </span>

        <img
          src={angleDown}
          className={`w-3 h-3 ml-2 ${isOpen ? "transform rotate-180" : ""}`}
          alt="angle down"
        />
      </div>

      {isOpen && (
        <div className="absolute z-10 lg:w-60 w-40 -mt-2 bg-third border border-black   rounded-b-md shadow-lg">
          {eventBetTypes.length > 0 ? (
            eventBetTypes.map((ebte, index) => (
              <div
                key={`${ebte.betTypeId}-${ebte.betTypeSpecificId}`}
                className={`px-2 py-2 cursor-pointer text-xs lg:text-base 
                ${
                  selectedOption === ebte.betTypeId &&
                  ebte.betTypeSpecificId ===
                    appStateStore.currentEventBetType?.betTypeSpecificId
                    ? "bg-secondary font-bold text-primary"
                    : index === 0 &&
                      appStateStore.currentEventBetType?.betTypeId === undefined
                    ? "bg-secondary font-bold text-primary"
                    : "bg-third text-primary hover:bg-secondary"
                }`}
                onClick={() => {
                  handleOptionClick(ebte.betTypeId, ebte.betTypeSpecificId);
                  handleEventBetTypeClick(ebte);
                }}
              >
                {ebte.description}
              </div>
            ))
          ) : (
            <p className="text-center">No EventBetType available</p>
          )}
        </div>
      )}
    </div>
  );
};

export default EventBetTypes;