
import React, { useState, useEffect, useContext } from "react";
import { fetchCategory, fetchTournament, fetchEvent, fetchCategoryTournament, fetchUpcomingTournament } from "../services/PunterService";
import { Category, Tournament, Event } from "../types/Types";
import { AppContext } from "../services/ContextProvider";
import horse from '../Icons/horse.svg';
import house from '../Icons/house.svg';
import angleDown from "../Icons/angleDown.svg";
import star from "../Icons/star.svg";
import starBlue from "../Icons/starBlue.svg"
import angleDownWhite from "../Icons/angleDownWhite.svg"

interface CategoryBrowserProps {
  handleNavigation: (viewNumber: number) => void;
  handleEventClick: (event: Event, tourn: Tournament) => void;
  handleTournamentClick: (tourn: Tournament) => void;
}

const CategoryBrowserComponent: React.FC<CategoryBrowserProps> = ({ handleEventClick, handleNavigation, handleTournamentClick }) => {
  const [categorys, setCategorys] = useState<Category[]>([]);
  const { appStateStore, setAppStateStore } = useContext(AppContext);
  const [expandedTournaments, setExpandedTournaments] = useState<string[]>([]);
  const [categoryButtonStates, setCategoryButtonStates] = useState<{ [categoryId: number]: boolean; }>({});
  const [isFavouritesDropdownVisible, setFavouritesDropdownVisible] = useState<boolean>(false);
  const [favouriteCategories, setFavouriteCategories] = useState<Category[]>([]);
  const [clickedEvent, setClickedEvent] = useState<{ eventId: number | null, tournamentId: number | null }>({ eventId: null, tournamentId: null });

  //Making things local so it can be available for only this component
  const [tournamentList, setTournamentList] = useState<Tournament[]>([])
  const [tournament, setTournament] = useState<Tournament>()
  const [clickedCategory, setClickedCategory] = useState<number>()
  const [clickedTournament, setClickedTournament] = useState<number>()
  const [clickedDate, setClickedDate] = useState<number>()
  const [categoryTournaments, setCategoryTournaments] = useState<Tournament[]>()
  const [upcomingTournaments, setUpcomingTournaments] = useState<Tournament[]>()

  //Dropdowns
  const [showDropdown, setShowDropdown] = useState<number | null | boolean>(null);
  const [showDateDropdown, setShowDateDropdown] = useState<number | null>(null);
  const [showTournamentsDropdown, setShowTournamentsDropdown] = useState(false);
  const [showUpcomingTournamentsDropdown, setShowUpcomingTournamentsDropdown] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const handleFillButtonClick = (categoryId: number) => {
    setCategoryButtonStates((prevButtonStates) => ({
      ...prevButtonStates,
      [categoryId]: !prevButtonStates[categoryId],
    }));

    setFavouriteCategories((prevFavourites) => {
      const selectedCategory = categorys.find(
        (cat) => cat.categoryId === categoryId
      );

      if (selectedCategory) {
        const isAlreadyAdded = prevFavourites.some(
          (cat) => cat.categoryId === categoryId
        );

        if (!isAlreadyAdded) {
          return [...prevFavourites, selectedCategory];
        } else {
          return prevFavourites.filter((cat) => cat.categoryId !== categoryId);
        }
      }

      return prevFavourites;
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      const _categories = await fetchCategory(1);
      setCategorys(_categories);
    };
    fetchData();
  }, [tournamentList]);


  const handleCategoryClick = async (category: Category) => {
    if (category) {
      const _tournaments = await fetchTournament(1, category.categoryId);

      setTournamentList(_tournaments)
      toggleDropdown(category.categoryId);
      setClickedCategory(prevCategory => prevCategory === category.categoryId ? 0 : category.categoryId);
      //setExpandedTournaments([])
    }
  };

  const handleDateClick = async (tournament: Tournament) => {
    if (tournament) {
      const _tournaments = await fetchTournament(1, tournament.categoryId);

      setClickedTournament(prevClickedTournament => prevClickedTournament === tournament.tournamentId ? 0 : tournament.tournamentId);
      setTournament(tournament)
      setTournamentList(_tournaments)
      toggleDateDropdown(tournament.tournamentId);
      
    }
  };

  const toggleDropdown = (categoryId: number) => {
    setShowDropdown((prevShowDropdown) =>
      prevShowDropdown === categoryId ? null : categoryId
    );
  };

  const toggleDateDropdown = (tournamentId: number) => {
    setShowDateDropdown((prevShowDateDropdown) =>
      prevShowDateDropdown === tournamentId ? null : tournamentId
    );
  };

  const handleTournamentDateClick = async (tournament: Tournament, categoryId: number, tournamentId: number) => {
    if (tournament) {
      const _tournaments = await fetchTournament(1, categoryId);
      const events = await fetchEvent(1, tournamentId);
      setAppStateStore((appStateStore) => ({
        ...appStateStore,
        currentEventList: events.sort((a, b) => a.tournamentId - b.eventId)
      }));
      // Toggle clicked date between its value and 0
      setClickedDate(prevClickedDate => prevClickedDate === tournamentId ? 0 : tournamentId);
      setTournament(tournament);
      setTournamentList(_tournaments);
      setExpandedTournaments((prevExpandedTournaments) =>
        prevExpandedTournaments.includes(tournament.tournamentId.toString())
          ? prevExpandedTournaments.filter(
            (id) => id !== tournament.tournamentId.toString()
          )
          : [...prevExpandedTournaments, tournament.tournamentId.toString()]
      );
    }
  };

  //displays all available tournaments 
  const handleCategoryTournamentClick = async () => {
    const _categoryTournament = await fetchCategoryTournament(1);

    // Sort tournaments alphabetically
    const sortedTournaments = _categoryTournament.sort((a, b) => {
      if (a.tournamentName && b.tournamentName) {
        return a.tournamentName.localeCompare(b.tournamentName);
      } else {
        return 0;
      }
    }
    );
    setCategoryTournaments(sortedTournaments);
  };

  //displays tournaments based on the current date
  const handleUpcomingTournamentClick = async () => {
    const today: Date = new Date();
    const formattedDate: string = today.toISOString().slice(0, 10);
    const _upcomingTournament = await fetchUpcomingTournament(1, formattedDate, formattedDate);
    // Sort tournaments alphabetically
    const sortedTournaments = _upcomingTournament.sort((a, b) => {
      if (a.tournamentName && b.tournamentName) {
        return a.tournamentName.localeCompare(b.tournamentName);
      } else {
        return 0;
      }
    });
    setUpcomingTournaments(sortedTournaments);
  };

  const handleFavouritesClick = () => {
    setFavouritesDropdownVisible(!isFavouritesDropdownVisible);
  };

  const handleCategoryForFavouritesClick = () => {
    setFavouritesDropdownVisible(false);
  };

  //Allows the user to click on the Event and directed to the fixed race viewer for with the events of the clicked markets
  const handleClick = (eventId: number, tournamentId: number, event: Event, tourn: Tournament, category: Category) => {
    if (clickedEvent.eventId === eventId && clickedEvent.tournamentId === tournamentId) {
      //setClickedEvent({ eventId: null, tournamentId: null });
    } else {
      setClickedEvent({ eventId, tournamentId });
      handleEventClick(event, tourn);
      handleNavigation(1);
      setAppStateStore((appStateStore) => ({
        ...appStateStore,
        currentCategory: category,
        currentTournament: tourn
      }));
    }
  };
  //allows navigation to corresponding event(FixedBetViewer) when a tournament within the Upcoming Dropdown is clicked 
  const handleTournamentSelect = (tournament: Tournament) => {
    setShowDropdown(false);
    if ('tournamentId' in tournament) {
      // Tournament is selected
      setAppStateStore((appStateStore) => ({
        ...appStateStore,
        currentCategory: tournament,
      }));
    }
  };

  // Allows my bets to be shown
  const handleBets = async () => {
    setAppStateStore((appStateStore) => ({
      ...appStateStore,
      isPopUpMyBets: true,
      isCurrentSessionTokenAvailable:true
    }));
  }
  //Open DropDown
  const toggleAccountDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleCategory = async (category: Category) => {
    if (category) {
      const _tournaments = await fetchTournament(1, category.categoryId);
      setAppStateStore((appStateStore) => ({
        ...appStateStore,
        currentCategory: category,
        currentTournamentList: _tournaments,
      }));
    }
  };

  const handleCategorySelect = (category: Category) => {
    if ('categoryId' in category) {
      // Category is selected
      setAppStateStore((appStateStore) => ({
        ...appStateStore,
        currentCategory: category,
      }));
    }
  };

  return (
    <div className="text-left text-secondary lg: px-5 pb-12 shadow-2xl lg:h-screen h-screen lg:w-1/6 w-3/4 fixed bg-primary overflow-y-scroll overflow-x-hidden max-h-[100vh] font-inter border-r-2 border-third">
      <div className=" mb-10 py-[7px] fl max-h-[vexh] w-[16.7vw] ml-[-1.1vw]">
        <div className="w-full flex p-1 ">
          <div className="lg:w-1/2 w-4/5 ">
            <h2 className="text-xl pt-2 pl-6 font-bold lg:text-left text-right text-secondary">
              Horse Racing
            </h2>
          </div>
          <div className="w-2/5 flex flex-col items-end lg:hidden">
            <a href="/">
              <img src={house} className="w-[30px]" alt="house" />
            </a>
          </div>
          <div className="w-1/2  lg:w-1/2 hidden lg:block">
            <div className="w-full flex flex-col">
              <img src={horse} className="w-[30px] pt-2" alt="horse" />
            </div>
          </div>
        </div>
      </div>
      <div className="italic font-bold">
        <p
          className="py-1 text-lg cursor-pointer"
          onClick={handleFavouritesClick}
        >
          Favourites
        </p>
        {isFavouritesDropdownVisible && (
          <div className="ml-4">
            {favouriteCategories.map((cat) => (
              <p
                key={cat.categoryId}
                className="py-1 text-base cursor-pointer"
                onClick={() => {
                  handleCategoryForFavouritesClick();
                }}
              >
                {cat.categoryName}
              </p>
            ))}
          </div>
        )}
        <div className="relative">
          <p
            className="py-1 text-lg cursor-pointer"
            onClick={() => {
              setShowUpcomingTournamentsDropdown(
                !showUpcomingTournamentsDropdown
              );
              handleUpcomingTournamentClick();
            }}
          >
            Upcoming
          </p>
          {showUpcomingTournamentsDropdown && (
            <div className="absolute top-full left-0  w-full bg-third rounded-md z-10">
              {upcomingTournaments?.map((tournament) => (
                <p
                  key={tournament.tournamentId}
                  className={`py-1 px-4 cursor-pointer text-sm hover:text-white
                    ${
                      appStateStore.currentTournament?.tournamentId ===
                      tournament.tournamentId
                        ? " bg-secondary text-primary rounded-md"
                        : ""
                    }`}
                  onClick={() => {
                    handleTournamentSelect(tournament);
                    handleTournamentClick(tournament);
                    tournament.events &&
                      tournament.events.length > 0 &&
                      handleEventClick(tournament.events[0], tournament);
                    handleNavigation(1);
                  }}
                >
                  {tournament.tournamentName}
                </p>
              ))}
            </div>
          )}
        </div>
        <div className="relative">
          <p
            className="pt-1 pb-5 text-lg cursor-pointer "
            onClick={() => {
              setShowTournamentsDropdown(!showTournamentsDropdown);
              handleCategoryTournamentClick();
            }}
          >
            Tournaments
          </p>
          {showTournamentsDropdown && (
            <div className="absolute top-full left-0 w-full bg-third rounded-md z-10 ">
              {categoryTournaments?.map((tournament) => (
                <p
                  key={tournament.tournamentId}
                  className={`py-1 px-4 cursor-pointer text-sm hover:text-white ${
                    appStateStore.currentTournament?.tournamentId ===
                    tournament.tournamentId
                      ? "bg-secondary text-primary rounded-md "
                      : ""
                  }`}
                  onClick={() => {
                    handleTournamentSelect(tournament);
                    handleTournamentClick(tournament);
                    tournament.events &&
                      tournament.events.length > 0 &&
                      handleEventClick(tournament.events[0], tournament);
                    handleNavigation(1);
                  }}
                >
                  {tournament.tournamentName}
                </p>
              ))}
            </div>
          )}
        </div>
      </div>

      <div className="w-full flex">
        <div className="font-bold w-full">
          {/* This is the list of Categories|| Countries */}
          {categorys &&
            categorys.map((cat) => (
              <div className="w-full flex" key={cat.categoryId}>
                <div className="font-bold py-1 w-full">
                  <div
                    className={`w-full flex px-[-10px] ${
                      clickedCategory === cat.categoryId
                        ? "bg-secondary rounded-md"
                        : ""
                    }`}
                  >
                    <div className="w-5/6">
                      <p
                        key={`${cat.categoryId}-${cat.countryCode}`}
                        onClick={() => handleCategoryClick(cat)}
                        className={`text-lg cursor-pointer ${
                          clickedCategory === cat.categoryId
                            ? "text-primary font-bold pl-2 px-2"
                            : ""
                        }`}
                        style={
                          clickedCategory === cat.categoryId
                            ? { paddingLeft: "-20px", paddingRight: "-20px" }
                            : {}
                        }
                      >
                        {cat.categoryName}
                      </p>
                    </div>
                    <div className="flex pb-2 pl-8 ">
                      {/* Shows if the category is favourite */}
                      {categoryButtonStates[cat.categoryId] ? (
                        <button
                          onClick={() => handleFillButtonClick(cat.categoryId)}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            height="24"
                            width="18"
                            viewBox="0 0 576 512"
                            fill={
                              clickedCategory === cat.categoryId
                                ? "black"
                                : "#FFD700"
                            }
                          >
                            <path d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z" />
                          </svg>
                        </button>
                      ) : (
                        <button
                          onClick={() => handleFillButtonClick(cat.categoryId)}
                        >
                          <img src={star} alt="star" />
                        </button>
                      )}
                    </div>
                  </div>
                  {/* When the CategoryName is Clicked it shows the list of Tournaments */}
                  {showDropdown === cat.categoryId && (
                    <ul className="ml-4 mt-2">
                      {tournamentList.map((tourn) => (
                        <li key={tourn.tournamentId}>
                          <div>
                            <div
                              className="cursor-pointer active:text-white flex items-start justify-between pr-8"
                              onClick={() => handleDateClick(tourn)}
                            >
                              <span
                                className={`${
                                  clickedTournament === tourn.tournamentId
                                    ? "bg-secondary text-primary rounded-md px-2"
                                    : ""
                                }`}
                              >
                                {tourn.tournamentName}{" "}
                              </span>
                              <img
                                src={angleDown}
                                className="h-4 w-4"
                                alt="angle down"
                              />
                            </div>
                          </div>
                          {showDateDropdown === tourn.tournamentId && (
                            <ul className="pl-4 bg-third rounded-md">
                              {tournament && (
                                <li key={tourn.tournamentId}>
                                  <div
                                    className={`${
                                      clickedDate === tourn.tournamentId
                                        ? "bg-third text-white rounded-t-md"
                                        : ""
                                    } 
                              cursor-pointer  flex items-center justify-between pr-8`}
                                    onClick={() =>
                                      handleTournamentDateClick(
                                        tourn,
                                        tourn.categoryId,
                                        tourn.tournamentId
                                      )
                                    }
                                  >
                                    {new Date(
                                      tourn.tournamentDate
                                    ).toLocaleDateString("en-GB", {
                                      day: "2-digit",
                                      month: "long",
                                      year: "numeric",
                                    })}
                                    <button>
                                      <img
                                        src={angleDown}
                                        className="h-4 w-4"
                                        alt="angle down"
                                      />
                                    </button>
                                  </div>
                                  {expandedTournaments.includes(
                                    tourn.tournamentId.toString()
                                  ) && (
                                    <div className="pl-5 text-secondary bg-third rounded-b-md">
                                      {/* Nested list for events */}
                                      <ul className="ml-4 ">
                                        {tourn.events &&
                                          tourn.events.map((evt) => (
                                            <li
                                              className={`pb-1 cursor-pointer text-md font-normal`}
                                              key={evt.eventId}
                                            >
                                              <button
                                                className={`${
                                                  appStateStore.currentEvent
                                                    ?.eventId === evt.eventId &&
                                                  appStateStore
                                                    .currentTournament
                                                    ?.tournamentId ===
                                                    tourn.tournamentId &&
                                                  tournament?.tournamentId ===
                                                    tourn.tournamentId
                                                    ? "border-b-2 border-secondary"
                                                    : ""
                                                }`}
                                                onClick={() =>
                                                  handleClick(
                                                    evt.eventId,
                                                    tourn.tournamentId,
                                                    evt,
                                                    tourn,
                                                    cat
                                                  )
                                                }
                                              >
                                                {`${evt.eventName} - ${new Date(
                                                  evt.eventDate
                                                ).getHours()}:
                                            ${String(
                                              new Date(
                                                evt.eventDate
                                              ).getMinutes()
                                            ).padStart(2, "0")}`}
                                              </button>
                                            </li>
                                          ))}
                                      </ul>
                                    </div>
                                  )}
                                </li>
                              )}
                            </ul>
                          )}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              </div>
            ))}
        </div>
      </div>

      <div className="fixed bottom-1 left-2 ">
        {/* My Account Menu */}
        <div className="relative inline-block text-left">
          <button
            id="dropdownDefaultButton"
            className="text-primary bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 
      font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:bg-secondary dark:hover:bg-secondary_hover dark:focus:ring-third"
            onClick={toggleAccountDropdown}
          >
            My Account
            <img
              src={angleDownWhite}
              className={`w-2.5 h-2.5 ms-3 transition-transform text-primary ${
                isOpen ? "rotate-360" : "-rotate-180"
              }`}
              alt="angleDown"
            />
          </button>
          {isOpen && (
            <div
              id="dropdown"
              className="z-10 absolute bg-white divide-y divide-gray-100 rounded-lg shadow w-44 mt-1 dark:bg-gray-700 bottom-full"
            >
              <ul
                className="py-2 text-sm text-gray-700 dark:text-gray-200"
                aria-labelledby="dropdownDefaultButton"
              >
                {/* <li>
                  <p className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Transaction</p>
                </li> */}
                <li onClick={handleBets}>
                  <p className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">
                    My Bets
                  </p>
                </li>
              </ul>
              {/* Triangle indicator */}
              <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 bg-white border-t border-gray-100 w-4 h-4 rotate-45"></div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CategoryBrowserComponent;
