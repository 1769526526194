import React from "react";
import { Market } from "../../types/Types";
import marketArrowUp from "../../Icons/marketArrowUp.svg";
import marketArrowUpSmallScreen from "../../Icons/marketArrowUpSmallScreen.svg";

interface Props {
    currentMarket: Market;
}

const SharedMarketListComponent: React.FC<Props> = ({ currentMarket }) => {

return (
    <>
        <td>
            <div className="flex items-center text-center">
                <div className="fill hidden lg:block lg:px-2">
                <img src={marketArrowUp} className="ml-2" alt="market arrow up" />
                </div>

                <div className="flex items-center text-center">
                <div className="lg:text-3xl text-sm hidden lg:block lg:px-2 lg:px-5 font-bold">
                  {currentMarket.marketNumber}
                </div>

                     {/* market number display for small screens */}
                <div className="flex flex-col p-2 text-left">
                  <p className="flex-1 text-secondary font-semibold mb-1 text-[10px] lg:text-lg">
                    <span className="lg:hidden inline-block px-[1px] text-black pr-1">
                      {currentMarket.marketNumber}
                    </span>

                    {currentMarket.marketName.toUpperCase()}

                      {/* arrow display for small screens */}
                    <span className="lg:hidden inline-block pl-1">
                      <img src={marketArrowUpSmallScreen} alt="market arrow up" />
                    </span>
                  </p>

                    {currentMarket.silk && currentMarket.silk.length>1  && (
                      currentMarket.silk
                        .filter(slk => slk.marketNumber === currentMarket.marketNumber)
                        .map(slk => (
                          <div key={slk.marketNumber} className="lg:text-base text-xs" >
                              <p>
                                {slk.jockeyName} / {slk.horseOwner}
                              </p> 
                              <p className="italic font-light">
                                {slk.jockeyWeight}kgs,
                                {slk.horseAge} yrs,
                                {slk.horseGender.toUpperCase()}
                              </p>
                          </div>
                        ))
                    )}
                  </div>
            </div>
            </div>
        </td>
        </>
    );
};

export default SharedMarketListComponent;
