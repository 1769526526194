import React, { useContext, useEffect, useState } from "react";
import { Event, Tournament, } from "../../types/Types";
import { AppContext } from "../../services/ContextProvider";
import RaceDropDownComponent from "./MainMenu/RaceDropDownComponent";
import EventBetTypeDropDownComponent from "./MainMenu/EventBetTypeDropDownComponent";
import ExoticBetViewerComponent from "./ExoticBetViewerComponent";
import ReturnComponent from "./MainMenu/ReturnComponent";
import FixedBetViewerComponent from "./FixedBetViewerComponent";
import OpenBetViewerComponent from "./OpenBetViewerComponent";

interface MainBetViewerProps {
  handleNavigation: (viewNumber: number) => void;
  handleEventClick: (event: Event, tourn: Tournament) => void;
}

const MainBetViewerComponent: React.FC<MainBetViewerProps> = ({ handleNavigation, handleEventClick}) => {
  const { appStateStore} = useContext(AppContext);
  const [selectedRace, setSelectedRace] = useState<number | null>(null);
  const [selectedEventBetTypeId, setEventBetTypeId] = useState<number>(1);
  const [viewMode, setViewMode] = useState(1);

    useEffect(() => {
    
        if (selectedEventBetTypeId > 5 && selectedEventBetTypeId < 13) {
          setViewMode(3);
        } else if ((selectedEventBetTypeId > 1 && selectedEventBetTypeId < 6) || (selectedEventBetTypeId===13)) {
          setViewMode(2);
        } else {
          setViewMode(1);
        }
    }, [selectedEventBetTypeId, appStateStore.currentEventBetType?.eventGroupId]);

  return (
    <>
      <div className="font-inter">
        <ReturnComponent handleNavigation={handleNavigation} />
        
        <div className="border-t border-third w-full border ">
          <div className="bg-primary flex w-full p-5">

            <div className="flex w-1/2">
              <RaceDropDownComponent
                handleEventClick={handleEventClick}
                onChange={setSelectedRace}
              />
            </div>

            <div className="flex w-1/2 lg:justify-end justify-center lg:items-end items-end">
              <EventBetTypeDropDownComponent onSelect={setEventBetTypeId} />
            </div>
          </div>
        
        </div>
        <div>
        {viewMode===3 && (
          <ExoticBetViewerComponent selectedEventBetTypeId={selectedEventBetTypeId} eventGroupId={appStateStore.currentEventBetType?appStateStore.currentEventBetType?.eventGroupId:4}/>
        )}

        {viewMode===2 && (
          <OpenBetViewerComponent/>
        )}
        {viewMode===1 && (
          <FixedBetViewerComponent/>
        )}
        </div>      
      </div>
   
    </>
  );
};

export default MainBetViewerComponent;
